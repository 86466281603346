<template lang="pug">
    .error-block
      .error-block__container
        img(
          srcset="images/static/box.png, images/static/box@2x.png 2x"
          src="images/static/box.png"
          class="error-block__image",
          alt="empty box"
        )
        .error-block__text {{$t('messages.errorPageText')}}
        a(href="./", class="button") {{$t('buttons.goHome')}}
</template>

<script>
export default {
  name: 'ErrorPage',
  created () {
    document.getElementsByTagName('body')[0].className = 'background-filled'
  }
}
</script>

<style>
</style>
